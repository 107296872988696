import React, { PureComponent } from "react";
import { graphql, navigate } from "gatsby";
import PropTypes from "prop-types";
import { isArray, isEmpty, get } from "lodash";

const propTypes = {
	data: PropTypes.object.isRequired,
};

class Services extends PureComponent {
	render() {
		const { data } = this.props;
		const {
			allFile: { edges: servicesData },
		} = data;

		if (!isArray(servicesData) || isEmpty(servicesData)) {
			return <div>Service is empty</div>;
		}

		// SSR check
		if (typeof window !== "undefined") {
			const firstServiceSlug = get(servicesData[0], "node.childMarkdownRemark.fields.slug");
			// navigate(firstServiceSlug);

			// * redirect to home instead of first service page
			navigate("/");
		}
		return null;
	}
}
Services.propTypes = propTypes;
export default Services;

export const query = graphql`
	query {
		allFile(filter: { relativeDirectory: { eq: "services" } }) {
			edges {
				node {
					childMarkdownRemark {
						frontmatter {
							service_title
						}
						fields {
							slug
						}
					}
				}
			}
		}
	}
`;
